/* 
=================
Table
=====================
*/

.table_container {
  max-width: 750px;
  /* max-height: 500px; */
  margin: 0 auto;
  overflow: auto;
}

.table {
  width: 100%;
  border-spacing: 0;
}

caption {
  font-style: italic;
  font-size: 90%;
  margin-bottom: 10px;
}

.table th {
  background: #fff;
  padding: 8px 10px;
  border-bottom: 1px solid #1a1a1a;
  font-weight: bold;
  text-align: left;
}

/* .table th.up {
    background-image: url("../public/images/up_arrow.png");
  }
  .table th.down {
    background-image: url("../public/images/down_arrow.png");
  }
  .table th.default {
    background-image: url("../public/images/default.png");
  } */
th.up,
th.default,
th.down {
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center right;
}

.table td {
  border-top: 1px solid #ddd;
  padding: 8px 20px;
}

.table tbody tr:first-child td {
  border-top: none;
}

.table tbody tr:nth-child(n) td {
  background: #eff0f0;
}

.table tbody tr:nth-child(2n) td {
  background: #fff;
}

.react-datepicker-popper {
  z-index: 300 !important;
}